<template>
    <validation-provider
        ref="provider"
        slim
        :detect-input="false"
        :rules="rules"
        :name="fieldName"
        :vid="$attrs.vid"
    >
        <v-container v-resize="onResize" class="pa-0">
            <v-carousel
                ref="container"
                v-model="slide"
                class="amp-video-preview"
                :class="{ zoom: isFullScreen, light: useLightTheme }"
                :height="previewHeight"
                show-arrows-on-hover
                prev-icon="angle-left"
                next-icon="angle-right"
                :delimiter-icon="delimiterIcon"
                :continuous="false"
                :hide-delimiters="isLoading"
            >
                <a-box-actions
                    v-if="!isLoading"
                    :options="actionOptions"
                    :readonly="readonly"
                    @change="change"
                    @media-deleted="onMediaDeleted"
                />
                <v-carousel-item v-if="isLoading" :key="1">
                    <div class="slide-container" :style="slideStyle">
                        <v-progress-circular
                            v-if="isLoading"
                            class="progress-indicator"
                            indeterminate
                            color="white"
                            size="350"
                            width="30"
                        />
                    </div>
                </v-carousel-item>
                <template v-for="slideNumber in totalSlides" v-else>
                    <v-carousel-item
                        v-if="slideAcceptsUserInput(slideNumber - 1)"
                        :key="slideNumber"
                        :value="slideNumber"
                    >
                        <a-slide-actions
                            :boxes="getVisibleBoxesForSlide(slideNumber - 1)"
                            :readonly="readonly"
                            @change="change"
                            @toggle-zoom="toggleZoom"
                            @toggle-theme="toggleTheme"
                        />
                        <div class="slide-container" :style="slideStyle">
                            <a-zoom-box
                                :box="getZoomBoxForSlide(slideNumber - 1)"
                            >
                                <a-video-box
                                    v-for="(box, i) in getVisibleBoxesForSlide(
                                        slideNumber - 1
                                    )"
                                    :key="box.id"
                                    :box="box"
                                    :index="i"
                                    :readonly="readonly"
                                    @change="change"
                                    @action="onBoxAction"
                                />
                            </a-zoom-box>
                        </div>
                    </v-carousel-item>
                </template>
            </v-carousel>
        </v-container>
    </validation-provider>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { ProvideReactive, Watch } from '@/utils/decorators';
import { wait } from '@/utils/helpers';
import { ValidationProvider } from 'vee-validate';

import { MediaModuleSlide } from '@/mixins';

import { AVideoBox } from '@/components/AVideoBox';
import { AZoomBox } from '@/components/AZoomBox';

import ABoxActions from '@/components/ASlideshow/SlideActions/ABoxActions.vue';
import ASlideActions from '@/components/ASlideshow/SlideActions/ASlideActions.vue';

const ASlideshowProps = Vue.extend({
    name: 'ASlideshow',
    props: {
        active: {
            type: Number,
            default() {
                return 0;
            }
        },
        delimiterIcon: {
            type: String,
            default() {
                return 'circle';
            }
        },
        linkEntity: {
            type: String,
            default() {
                return 'slide';
            }
        },
        allowStockVideoSearch: {
            type: Boolean,
            default() {
                return false;
            }
        },
        portrait: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        ASlideActions,
        ABoxActions,
        ValidationProvider,
        AVideoBox,
        AZoomBox
    }
})
export default class ASlideshow extends mixins(
    ASlideshowProps,
    MediaModuleSlide
) {
    linkEntityLabel = this.linkEntity;

    get slide() {
        return this.active;
    }

    set slide(slide: number) {
        this.$emit('slide', slide);
    }

    get previewHeight() {
        return this.portrait ? 800 : 500;
    }

    @Watch('previewHeight')
    onPortraitToggle() {
        wait(() => !this.isLoading).then(() => {
            setTimeout(() => this.onResize(), 300);
        });
    }

    @ProvideReactive() isFullScreen = false;
    @ProvideReactive() useLightTheme = false;
    @ProvideReactive('videoStockSearch')
    get videoStockSearchProvider() {
        return this.allowStockVideoSearch;
    }

    toggleZoom() {
        this.isFullScreen = !this.isFullScreen;

        this.$emit('zoom', this.isFullScreen);

        setTimeout(() => this.onResize(), 300);
    }

    toggleTheme() {
        this.useLightTheme = !this.useLightTheme;
    }
}
</script>

<style lang="scss" scoped>
.amp-video-preview::v-deep {
    background-color: $black;

    &.zoom {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5; // appear on top of the app top header
        height: calc(100vh - calc(100vh - 100%)) !important;
        width: calc(100vw - calc(100vh - 100%)) !important;
        min-height: -webkit-fill-available !important;
        min-width: -webkit-fill-available !important;
        transform: translate3d(0, 0, 0);

        .v-carousel__item {
            height: 100% !important;
        }

        .v-carousel__controls {
            transform: translateY(100%);
            opacity: 0;
            transition: 0.2s ease;
        }

        .actions {
            transform: translateY(-100%);
            opacity: 0;
            transition: 0.2s ease;
        }
    }

    &.light {
        background-color: $grey-light-darker;
    }

    .slide-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transition: transform 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        transform-origin: top left;
        overflow: hidden;
    }

    .progress-indicator {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .v-item--active.v-btn--active.v-btn--icon {
        color: $primary-color;
    }

    .v-carousel__controls {
        .v-carousel__controls__item {
            &:after {
                content: attr(value);
                position: absolute;
                color: $white;
                top: calc(-100% + 3px);

                transform: translateY(400%);
                opacity: 0;
                transition: 0.2s ease;
            }

            .v-btn__content:before {
                content: '';
                position: absolute;
                background-color: $primary-color;
                border-radius: 50% 50% 0;
                min-width: 22px;
                min-height: 22px;
                top: calc(-100% - 14px);

                transform: translateY(400%) rotate(45deg);
                opacity: 0;
                transition: 0.2s ease;
            }
        }
    }

    &:hover {
        .actions {
            transform: translateY(0);
            opacity: 1;
        }

        .v-carousel__controls {
            transform: translateY(0);
            opacity: 1;

            .v-carousel__controls__item {
                &:after {
                    transform: translateY(0);
                    opacity: 1;
                }

                .v-btn__content:before {
                    transform: translateY(0) rotate(45deg);
                    opacity: 1;
                }

                &.v-item--active {
                    &:after {
                        transform: translateY(-2px) scale(1.3);
                    }

                    .v-btn__content:before {
                        transform: translateY(-2px) rotate(45deg) scale(1.3);
                    }
                }
            }
        }
    }
}
</style>
